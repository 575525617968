import React, { Component } from 'react';

class MyRosterComponents extends Component {

   state = {
    
   }

   componentDidMount() {
      window.location.assign(localStorage.getItem('proxymemberemail') ? process.env.REACT_APP_ROSTER_URL+`?proxymemberemail=${localStorage.getItem('proxymemberemail')}` : process.env.REACT_APP_ROSTER_URL);
   }

   render() {
      return (
        <></>
      )
   }
}

export default MyRosterComponents;


