import { get } from 'lodash';
import { connect } from 'react-redux';
import { checkUser } from '../../../services/appServices';
import { oktaLoginAction } from '../../../services/userServices';
import { bannerFeatureAction } from '../../../services/userServices';
import App from './AppComponent';
import { withBannerHooks } from 'providers/banner';

const mapStateToProps = (state) => {
  const { user, app, i18nReducer, shared } = state;
  return {
    userInfo: user.userInfo,
    isAppLoading: app.isAppLoading,
    isMiniAppLoading: app.isMiniAppLoading,
    showToastMessage: shared.showToastMessage,
    content: get(i18nReducer.dictionaries[i18nReducer.currentLanguage], shared.code),
    isSignOutTrigger: user.isSignOutTrigger,
    uuidNumber: shared.uuidNumber
  }
};


const mapDispatchToProps = dispatch => {
  let actions = {
    checkUser: () => dispatch(checkUser()),
    oktaLogin: (data, cb) => oktaLoginAction(data, dispatch, cb),
    bannerFeature: (cb) => bannerFeatureAction(cb)
  }
  return { ...actions, dispatch };
}

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default withBannerHooks(AppContainer);