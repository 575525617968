
import React, { useEffect, useState } from 'react';
import qs from "query-string";
import { encodeText, setRedirectUrl, getUserInfo } from '../../../helpers/utilCommon';
import { loginHandler } from 'helpers/OktaUtility';
import { get } from 'lodash';

const RegistraionComponent = (props) => {
    const [getAccountInfo, setAccountInfo] = useState(null);
    const userInfo = props.userInfo;
    const accountNumber = get(userInfo, 'memberAccountNumber');
    useEffect(() => {
        let eventid = getQueryStringValue('eventid');
        let categoryid = getQueryStringValue('categoryid');
        // Check User Is Login
        if (accountNumber) {
            let isMember = get(userInfo, 'isMember');
            let data = {};
            data.eventid = eventid;            
            setAccountInfo(accountNumber);
            data.isMember=isMember;            
            if(isMember){
            data.accountNumber = encodeText(accountNumber);                        
            }
            else
            {
                data.email = get(userInfo, 'email');
                data.firstName = get(userInfo, 'firstName');
                data.lastName = get(userInfo, 'lastName');                
            }

            props.postDataToBridgeAPI(data, (responseBridgeAPI) => {
                if (responseBridgeAPI && eventid && categoryid)
                    window.location.assign(`${process.env.REACT_APP_AVENTRI_REDIRECT_URL}?eventid=${eventid}&categoryid=${categoryid}&bridgeid=${responseBridgeAPI}`);
                else if (responseBridgeAPI && eventid)
                    window.location.assign(`${process.env.REACT_APP_AVENTRI_REDIRECT_URL}?eventid=${eventid}&bridgeid=${responseBridgeAPI}`);
            });
        }
    }, [accountNumber]);

    const handle_NonMemberClick = () => {
        let eventid = getQueryStringValue('eventid');
        let categoryid = getQueryStringValue('categoryid');
        if (eventid && categoryid)
            window.location.assign(`${process.env.REACT_APP_AVENTRI_REDIRECT_URL}?eventid=${eventid}&categoryid=${categoryid}`);
        else if (eventid)
            window.location.assign(`${process.env.REACT_APP_AVENTRI_REDIRECT_URL}?eventid=${eventid}`);
    }

    const handle_MemberLoginClick = () => {
        setRedirectUrl(window.location.href.replace(/.*\/\/[^\/]*/, ''));
        loginHandler();
    }

    const getQueryStringValue = (
        key,
        queryString = window.location.search
    ) => {
        const values = qs.parse(queryString);
        return values[key];
    };

    return (
        <>
            {getAccountInfo ? <div style={{ height: 400 }} data-testid="aventriRegLoggedCom"></div> : <div className="container" data-testid="aventriRegGuestCom">
                <div className="publicLogin">
                    <div className="page-title-wrapper">
                        <h1 className="page-title astm-type-heading--h1">Member/Registered User Login</h1>
                    </div>

                    <div className="astm-type-body--lead">
                        <h4 className="astm-type-heading--h4">Member/Registered Users</h4>
                        <p className="astm-type-body--lead">If you have already registered an account as a Member or Registered user (Non-Member), please proceed to sign in.</p>
                        <div className="actions-toolbar" onClick={handle_MemberLoginClick}>
                            <a href="#" className="btn astm-btn btn-primary w-100" data-testid="signin_btn">Sign In</a>
                        </div>
                    </div>
                    <div className="solid-border"></div>
                    <div>
                        <h4 className="astm-type-heading--h4">Non-Members</h4>
                        <div className="astm-type-body--lead" aria-labelledby="block-new-customer-heading">
                            <p className="astm-type-body--lead">If you do not  have a member/registered user account with ASTM and wish to Register for an Event, please proceed to Pre-Register as a Non-Member</p>
                            <div className="actions-toolbar" onClick={handle_NonMemberClick}>
                                <a href="#" className="btn astm-btn btn-secondary w-100" data-testid="guest_btn">Pre-Register as a Non-Member</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    );
}
export default RegistraionComponent;
