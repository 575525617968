import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { signOut } from "redux/login/actions";

const IdentityDelegationBanner = (identityDelegation) => {
    const { proxyMemberFirstName, proxyMemberLastName } = identityDelegation;
    return (
        <div>
            <div className="card astm-card astm-card--flat alert-danger">
                <div className="container">
                    <div className={"card-text d-flex flex-row justify-content-between pt-2 pb-2"}>
                        <span className={"astm-type-body--lead align-self-center renewal-reinstate-widget-descriptive-text mb0"}>
                            <i className="astm-icon astm-icon astm-icon--light-danger fal fa-exclamation-square"></i>&nbsp;
                            Identity Delegation Active for - <b>{proxyMemberFirstName} {proxyMemberLastName} ({(identityDelegation && identityDelegation.userInfo && identityDelegation.userInfo.memberAccountNumber)})</b></span>
                        <button className={"btn astm-btn btn-outline-danger align-self-center"} onClick={identityDelegation.signOut}>Exit Delegation</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = dispatch => ({
    signOut: (isTrigger = true) => dispatch(signOut(dispatch, isTrigger))
})
export default connect(null, mapDispatchToProps)(withRouter(IdentityDelegationBanner));