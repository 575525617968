import MyAccountHeader from '@astm/astm-member-app-my-account-header';
import { membershipTypeJSON } from 'helpers/constant';
import { find, get, isEmpty, stubFalse } from 'lodash';
import { ComponentUrls, StaticPages } from 'models/navigation.models';
import React, { Component } from "react";
import { Button, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { memberNickFormat } from '../../helpers/utilCommon';
import { signOut } from '../../redux/login/actions';
import MyToolMenu from '../atoms/MyToolMenu';
import HeaderSearchBox from "../atoms/SearchBox";
import RenderMobileMenu from './MobileMenu';
import logo_primary_astm_tagline from 'astm-ux-design-system/dist/assets/img/ASTM logo_primary_astm_tagline.png'

class HeaderComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenMobileMenu: false,
            isMobileViewSiteSearchDropDownEnabled: false,
            canShow:false
        };
    }

    componentDidMount(){
        setTimeout( () => this.setState({canShow:true}) , 1000)
    }

    //TODO : Conditionally handle login path.
    async loginHandler(e) {
        let loginPath = await this.props.loginHandler();
        if (loginPath) {
            this.props.history.push(loginPath)
        }
    }

    handleMyToolMenu = (isOpen) => {
        this.setState({ isOpenMobileMenu: isOpen }, () => {
            if (this.state.isOpenMobileMenu) {
                const body = document.getElementsByTagName("body")[0];
                body.classList.add("no-scroll");
            }
            else {
                const body = document.getElementsByTagName("body")[0];
                body.classList.remove("no-scroll");
            }
        })
    }

    // To handle mobile view dropdown toggle on site search.
    siteSeachToggleHandler = (e) => {
        e.stopPropagation();
        const { isMobileViewSiteSearchDropDownEnabled } = this.state;
        this.setState({
            isMobileViewSiteSearchDropDownEnabled: !isMobileViewSiteSearchDropDownEnabled
        });
    }
    // To hide dropdown flag on Menu and MyTool
    hideSiteSearchFlag = () => {
        this.setState({
            isMobileViewSiteSearchDropDownEnabled: false
        });
    }

    render() {
        const { userInfo, demoGraphy, entitlementList, signOut, location } = this.props;
        const { isMobileViewSiteSearchDropDownEnabled, canShow } = this.state;
        const userName = isEmpty(demoGraphy) ? (isEmpty(userInfo) ? '' : (get(userInfo, 'firstName') ? memberNickFormat('', userInfo.firstName) : get(userInfo, 'name'))) : memberNickFormat(demoGraphy.NickName, demoGraphy.FirstName);
        let isMyCommittees = null;
        let isStudentMember = false;
        let showMyTool = false;
        if (!isEmpty(entitlementList.menuList)) {
            const membershipMenuList = find(entitlementList.menuList, { "title": "Membership" });
            isMyCommittees = find(membershipMenuList.child, { "title": "MyCommittees" });
        }
        if (!isEmpty(entitlementList.otherInfo) && !isEmpty(entitlementList.otherInfo.membershipTypeDetails)) {
            isStudentMember = entitlementList.otherInfo.membershipTypeDetails.MembershipTypeId === membershipTypeJSON.student.id
        }

        if (location &&
            (location.pathname === "/Msgs/classification" ||
                location.pathname === "/Msgs/votingstatus" ||
                location.pathname === "/Msgs/nonvotereason" ||
                location.pathname === "/MyASTM/MembershipInfo/Card" ||
                location.pathname === "/MyASTM/MembershipInfo/Certificate" ||
                location.pathname === "/MyASTM/MembershipInfo/Membership-Renewal-Invoice" ||
                location.pathname === "/MyASTM/MembershipInfo/Membership-Receipt" ||
                location.pathname.toLowerCase() === StaticPages.StandardsTracking.toLowerCase() ||
                location.pathname.toLowerCase() === StaticPages.CitingASTMStandards.toLowerCase())
        ) {
            return null;
        }

        if (location.pathname.toLowerCase() === ComponentUrls.MyCommittees.toLowerCase()) {
            showMyTool = true;
        }

        return (
            <header data-testid="header-component">

                <div className="container ">
                    <div className="headerWrap">
                        <div className="logoWrap">
                            <a href={ `${process.env.REACT_APP_PUB_URL}`} target="_blank" rel="noreferrer">
                               <img alt="ASTM Logo" src={logo_primary_astm_tagline}/>
                            </a>
                        </div>

                        <div className="headerFilterhWrap">
                            <div className="searchWrap">
                                <HeaderSearchBox />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="topMenuBar">
                    <div className="container">
                        <Navbar expand="lg">
                            <Nav>
                                <NavDropdown
                                    data-testid="nav-top-1"
                                    title="Products &amp; Services"
                                    className="pnsLink"
                                    id="basic-nav-dropdown-1"
                                >
                                    <div data-testid="nav-child-1" className="flex">
                                        <div className="">
                                            <div className="menuTitle">Products</div>
                                            <div className="level2Menu">
                                                <NavDropdown title={<a href={process.env.REACT_APP_PUB_URL + '/products-services/standards-and-publications.html'} className="dropdown-item type2">Standards &amp; Publications <i className=" fas fa-caret-right astm-icon"></i></a>}>
                                                    <div className="innerSubMenu" data-menu="Standards &amp; Publications">
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/standards-and-publications.html'}>All Standards and Publications</NavDropdown.Item>
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/standards-and-publications/standards.html'}>Standards Products</NavDropdown.Item>
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/standards-and-publications/symposia-papers.html'}>Symposia Papers &amp; STPs</NavDropdown.Item>
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/standards-and-publications/mnl.html'}>Manuals, Monographs, &amp; Data Series</NavDropdown.Item>
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/standards-and-publications/technical-reports.html'}>Technical Reports</NavDropdown.Item>
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/standards-and-publications/journals.html'}>Journals</NavDropdown.Item>
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/reading-room.html'}>Reading Room</NavDropdown.Item>
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/digital-library.html'}>Authors</NavDropdown.Item>
                                                    </div>
                                                </NavDropdown>
                                            </div>
                                            <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/bos.html'}>Book of Standards</NavDropdown.Item>
                                            <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/reading-room.html'}>Reading Room</NavDropdown.Item>
                                            <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/tracker-services.html'}>Tracker Services</NavDropdown.Item>
                                            <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/catalogs.html'}>Catalogs</NavDropdown.Item>
                                            <div className="menuTitle mt24">Services</div>
                                            <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/digital-library.html'}>Digital Library</NavDropdown.Item>
                                            <div className="level2Menu">
                                                <NavDropdown title={<a href={process.env.REACT_APP_PUB_URL + '/products-services/enterprise-solutions/integrated-services.html'} className="dropdown-item type2">Enterprise Solutions <i className=" fas fa-caret-right astm-icon"></i></a>}>
                                                    <div className="innerSubMenu" data-menu="Enterprise Solutions">
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/enterprise-solutions/integrated-services.html'}>Integrated Services</NavDropdown.Item>
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/enterprise-solutions/astm-compass.html'}>ASTM CompassÂ®</NavDropdown.Item>
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/enterprise-solutions/specbuilder.html'}>SpecBuilder</NavDropdown.Item>
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/enterprise-solutions/custom-platforms.html'}>Custom Platforms</NavDropdown.Item>
                                                    </div>
                                                </NavDropdown>
                                            </div>
                                            <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/proficiency-testing.html'}>Proficiency Testing</NavDropdown.Item>
                                            <div className="level2Menu">
                                                <NavDropdown title={<a href={process.env.REACT_APP_PUB_URL + '/products-services/training-courses.html'} className="dropdown-item type2">Training Courses <i className=" fas fa-caret-right astm-icon"></i></a>}>
                                                    <div className="innerSubMenu" data-menu="Training Courses">
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/training-courses.html'}>All Training Courses</NavDropdown.Item>
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/training-courses/international-training.html'}>International Training</NavDropdown.Item>
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/training-courses/pe-continuing-education.html'}>PE Continuing Education</NavDropdown.Item>
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/training-courses/member-training.html'}>Member and Officer Training</NavDropdown.Item>
                                                    </div>
                                                </NavDropdown>
                                            </div>
                                            <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/products-services/certification.html'}>Certification</NavDropdown.Item>
                                            <NavDropdown.Item href={process.env.REACT_APP_PUB_CEMENT_REFERENCE_LAB}>Cement &amp; Concrete Reference Lab</NavDropdown.Item>
                                        </div>
                                        <div className="">
                                            <ul>
                                                <li className="mt8">
                                                    <a href={process.env.REACT_APP_PUB_URL + '/products-services/bos.html'}>
                                                    <img src={"https://www.astm.org/media/wysiwyg/2024-BOS_Cover.jpg"} alt="2024 Annual Book of ASTM Standards" />

                                                    </a>
                                                </li>
                                                <li className="mt24">
                                                    <a href={process.env.REACT_APP_PUB_URL + '/products-services/enterprise-solutions/astm-compass.html'}>
                                                        <img src={process.env.REACT_APP_PUB_URL + '/static/version1633442940/frontend/ASTM/default/en_US/Alpine_CmsContent/images/nav/compass-new-logo.png'} alt="ASTM Compass Platform displayed on a laptop" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </NavDropdown>
                                <NavDropdown
                                    data-testid="nav-top-2"
                                    title="Get Involved"
                                    id="basic-nav-dropdown-2"
                                >
                                    <div data-testid="nav-child-2" className="flex">
                                        <div>
                                            <div className="menuTitle">Membership &amp; Meetings</div>
                                            <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/get-involved/technical-committees.html'}>Technical Committees</NavDropdown.Item>
                                            <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/get-involved/membership.html'}>Membership</NavDropdown.Item>
                                            <NavDropdown.Item href={process.env.REACT_APP_GLOBAL_MEETING_SYMPOSIA_URL}>Meetings &amp; Symposia</NavDropdown.Item>
                                            <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/get-involved/students-and-professors/for-students/memstudent22.html'}>Students &amp; Professors</NavDropdown.Item>
                                            <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/get-involved/publish-with-astm.html'}>Publish With ASTM</NavDropdown.Item>
                                        </div>
                                        <div>
                                            <ul>
                                                <li className="mt8">
                                                    <a href={process.env.REACT_APP_PUB_URL + '/get-involved/membership.html'}>
                                                        <img src={process.env.REACT_APP_PUB_URL + '/static/version1633442940/frontend/ASTM/default/en_US/Alpine_CmsContent/images/nav/get-involved-nav.jpg'} alt="Four businesspeopled standing around each other talking" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </NavDropdown>
                                <NavDropdown
                                    data-testid="nav-top-3"
                                    title="About"
                                    id="basic-nav-dropdown-3"
                                >
                                    <div data-testid="nav-child-3" className="flex">
                                        <div>
                                            <div className="menuTitle">Overview &amp; Key Facts</div>
                                            <div className="level2Menu">
                                                <NavDropdown title={<a href={process.env.REACT_APP_PUB_URL + '/about/overview.html'} className="dropdown-item type2">Overview <i className=" fas fa-caret-right astm-icon"></i></a>}>
                                                    <div className="innerSubMenu" data-menu="Overview">
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/about/overview.html'}>Overview</NavDropdown.Item>
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/about/overview/detailed-overview.html'}>Detailed Overview</NavDropdown.Item>
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/about/overview/fact-sheet.html'}>Fact Sheet</NavDropdown.Item>
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/about/overview/white-papers.html'}>White Papers</NavDropdown.Item>
                                                        <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/about/overview/corporate-citizenship.html'}>Corporate Citizenship</NavDropdown.Item>
                                                    </div>
                                                </NavDropdown>
                                            </div>
                                            <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/about/governance.html'}>Governance</NavDropdown.Item>
                                            <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/about/global-cooperation.html'}>Global Cooperation Home</NavDropdown.Item>
                                            <NavDropdown.Item href={process.env.REACT_APP_PUB_SN_LINK + '/?q=presidents-column'}>President's Column</NavDropdown.Item>
                                            <NavDropdown.Item href={process.env.REACT_APP_PUB_URL + '/about/diversity-inclusion.html'}>Diversity, Equity, and Inclusion</NavDropdown.Item>
                                        </div>
                                        <div>
                                            <ul>
                                                <li className="mt8">
                                                    <a href={process.env.REACT_APP_PUB_URL + '/about/global.html'}>
                                                        <img src={process.env.REACT_APP_PUB_URL + '/static/version1633442940/frontend/ASTM/default/en_US/Alpine_CmsContent/images/nav/about-nav.png'} alt="Digital map of the world" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </NavDropdown>
                                <NavDropdown
                                    data-testid="nav-top-4"
                                    title="News"
                                    className="newsLink"
                                    id="basic-nav-dropdown-4"
                                >
                                    <div data-testid="nav-child-4" className="flex">
                                        <div>
                                            <div className="menuTitle">News</div>
                                            <NavDropdown.Item href={process.env.REACT_APP_PUB_SN_LINK}>Standardization News</NavDropdown.Item>
                                            <NavDropdown.Item href={process.env.REACT_APP_PUB_NEWSROOM_LINK}>News Releases</NavDropdown.Item>
                                        </div>
                                        <div>
                                            <ul >
                                                <li className="mt8">
                                                    <a href="https://www.standardizationnews.com/standardizationnews/march_april_2021/MobilePagedArticle.action?articleId=1664626">
                                                        <img src={process.env.REACT_APP_PUB_URL + '/static/version1633442940/frontend/ASTM/default/en_US/Alpine_CmsContent/images/nav/MA21-SN-Cover-sm.jpg'} alt="Standardization News magazine cover" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </NavDropdown>
                                <RenderMobileMenu handleMobileMenu={this.handleMobileMenu} hideSiteSearchFlag={this.hideSiteSearchFlag} />
                                <NavDropdown show={isMobileViewSiteSearchDropDownEnabled} title="Site Search" className="mobileSite" onClick={(e) => this.siteSeachToggleHandler(e)}>
                                    <div className="commonLoader" onClick={(e) => e.stopPropagation()}>
                                        <div className="siteSearch">
                                            <HeaderSearchBox />
                                        </div>
                                    </div>
                                </NavDropdown>
                                {!isEmpty(userInfo) && showMyTool && (
                                    <NavDropdown data-testid="my-tools" title="MyTools" id="basic-nav-dropdown3" className="mobileMytools"
                                        onToggle={this.handleMyToolMenu}
                                        show={this.state.isOpenMobileMenu} onClick={(e) => this.hideSiteSearchFlag(e)}>
                                        <div class="commonLoader">
                                            <MyToolMenu handleMyToolMenu={this.handleMyToolMenu} />
                                        </div>
                                    </NavDropdown>
                                )}
                            </Nav >

                        </Navbar >
                        <div className="menuWrapper">
                            <ul>
                                <li>
                                    <a href={process.env.REACT_APP_PUB_URL + '/contact/'}>Contact</a>
                                </li>
                                <li>
                                    <a href={process.env.REACT_APP_PUB_URL + '/checkout/cart/'}>Cart</a>
                                </li>
                            </ul>
                            {!isEmpty(userInfo) && canShow && (
                                <MyAccountHeader
                                    isMemberApp={true}
                                    userName={userName}
                                    globalLinksURL={{
                                        profile: process.env.REACT_APP_GLOBAL_PROFILE_URL,
                                        membership: "/MyASTM",
                                        myCommitteesUrl: isMyCommittees ? isMyCommittees.url : '',
                                        compass: process.env.REACT_APP_GLOBAL_COMPASS_URL,
                                        orders: process.env.REACT_APP_GLOBAL_ORDERS_URL,
                                        tracker: process.env.REACT_APP_GLOBAL_TRACKERS_URL,
                                        elearning: process.env.REACT_APP_E_LEARNING_URL,
                                        specbuilder: process.env.REACT_APP_IS_SPB_ENABLED === 'true' ? process.env.REACT_APP_SPECBUILDER_HOST : '',
                                        meetingsSymposia: isStudentMember ? process.env.REACT_APP_GLOBAL_MEETING_SYMPOSIA_URL : ''
                                    }}
                                    signout={signOut}
                                />
                            )}
                            {!isEmpty(userInfo) && !canShow && (
                                <MyAccountHeader
                                    isMemberApp={true}
                                    userName={' '}
                                    globalLinksURL={{
                                        profile: process.env.REACT_APP_GLOBAL_PROFILE_URL,
                                        membership: "/MyASTM",
                                        myCommitteesUrl: isMyCommittees ? isMyCommittees.url : '',
                                        compass: process.env.REACT_APP_GLOBAL_COMPASS_URL,
                                        orders: process.env.REACT_APP_GLOBAL_ORDERS_URL,
                                        tracker: process.env.REACT_APP_GLOBAL_TRACKERS_URL,
                                        elearning: process.env.REACT_APP_E_LEARNING_URL,
                                        specbuilder: process.env.REACT_APP_IS_SPB_ENABLED === 'true' ? process.env.REACT_APP_SPECBUILDER_HOST : '',
                                        meetingsSymposia: isStudentMember ? process.env.REACT_APP_GLOBAL_MEETING_SYMPOSIA_URL : ''
                                    }}
                                    signout={signOut}
                                />
                            )}
                            {isEmpty(userInfo) && 
                             (
                                <Button data-testid="sign-in-btn" variant="primary" className="astm-btn astm-btn--small btn-primary" onClick={(e) => this.loginHandler(e)}>
                                    <span>Sign In</span> <i className="fas fa-user-circle ml8 astm-icon"></i>
                                </Button>
                            )}
                        </div>
                    </div >
                </div >
            </header >
        )
    }
}

const mapStateToProps = (state) => {
    const { user, entitlement } = state;
    return {
        userInfo: user.userInfo,
        demoGraphy: user.demoGraphy,
        entitlementList: entitlement.entitlementList
    }
}

const mapDispatchToProps = dispatch => ({
    signOut: (isTrigger = true) => dispatch(signOut(dispatch, isTrigger))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(HeaderComp));
