import { setMiniAppLoader } from 'redux/app/actions';
import { removeAppCookies } from "../../helpers/manageCookies";
import { logoutHandler } from "../../helpers/OktaUtility";
import { clearSession } from '../../helpers/utilCommon';
import { clearRedis } from "../../services/appServices";
import types from './types';

export const setUserInfo = userInfo => ({
    type: types.SET_USER_INFO,
    payload: userInfo
})

export const setUserPermission = userPermissionObj => ({
    type: types.SET_USER_PERMISSION,
    payload: userPermissionObj
})

export const setUserPermissionLoader = (value) => ({
    type: types.SET_USER_PERMISSION_LOADER,
    payload: value
})

export const setLoginMessage = msgObj => ({
    type: types.SET_LOGIN_MESSAGE,
    payload: msgObj
})

export const signOut = (dispatch = null, isSignOutTrigger = false) => {
    dispatch(setMiniAppLoader(true))
    clearRedis(() => {
        clearSession();
        removeAppCookies(false);
        logoutHandler();
    });
    return {
        type: types.SIGN_OUT,
        payload: isSignOutTrigger
    }
}

export const setMemberData = memberData => ({
    type: types.SET_MEMBER_DATA,
    payload: memberData
})

export const setDemoGraphy = siteData => ({
    type: types.SET_DEMO_GRAPHY,
    payload: siteData
})

export const updateNicknameInDemograpgy = (nickname) => ({
    type: types.UPDATE_NICK_NAME,
    payload: nickname
})

export const setLoggedInMemberDetails = memberData => ({
    type: types.SET_LOGGED_IN_MEMBER_DATA,
    payload: memberData
})

export default {
    setUserInfo,
    setUserPermission
}