import { assign, forEach, get, isEmpty } from 'lodash';
import { Urls, buildURL, callAPI } from "../helpers/apiUrls";
import { ValidationMessage } from '../helpers/constant';
import { removeCookie, setCookie } from "../helpers/manageCookies";
import { cacheManager, encodeText, encrypt, encryptData, getUUIDNumberFromLocalStorage } from '../helpers/utilCommon';
import { LOGIN_USER_MODEL, cookieKeysEnum } from '../models/common.models';
import { setAppLoader, setMiniAppLoader, setTestMode } from '../redux/app/actions';
import { setAppEntitlement, setEntitlement } from '../redux/entitlement/actions';
import {
  setDemoGraphy, setLoggedInMemberDetails,
  setLoginMessage, setMemberData, setUserInfo,
  setUserPermission, setUserPermissionLoader, updateNicknameInDemograpgy
} from '../redux/login/actions';
import { setMessage } from '../redux/shared/actions';

export const adminLoggedIn = (loginObj) => {
  return dispatch => {
    const { profile, password } = loginObj;
    if (isEmpty(password)) {
      dispatch(setLoginMessage({ isLoading: false, message: ValidationMessage.adminPasswordIncorrect }));
      return;
    }
    profile.memberAccountNumber = profile.memberAccountNumber.trim();
    if (!profile.memberAccountNumber) {
      dispatch(setLoginMessage({ isLoading: false, message: ValidationMessage.emailOrIdRequired }));
      return;
    }
    dispatch(setLoginMessage({ isLoading: true, message: null }));
    profile.memberAccountNumber = profile.memberAccountNumber.trim();

    const url = `${buildURL(Urls.MemberLogin, null, null)}`;
    const postData = {
      "username": profile.memberAccountNumber,
      "password": password
    }
    const sessionTime = new Date().getTime();
    setCookie(cookieKeysEnum['APP_SESSIONID'], sessionTime, process.env.REACT_APP_COOKIES_EXPIRATION_TIME);
    callAPI(url, 'post', postData, false, (response) => {
      if (response && response.ResponseStatus) {
        const { ResponseData } = response;
        setCookie(cookieKeysEnum["accessToken"], ResponseData.accessToken, 3);
        setCookie("refreshToken", ResponseData.refreshToken, 3);
        setCookie(cookieKeysEnum["_MyASTM"], profile.memberAccountNumber, 3);
        //setCookie("ILSCookie", encodeText(`${userInfo.email};${userInfo.memberAccountNumber}`));
        setCookie("token", ResponseData.accessToken, 3);
        setCookie(cookieKeysEnum["ACCOUNT-ID"], process.env.REACT_APP_SPECBUILDER_ACCOUNT_ID, 3);
        encryptData(profile, 'userInfo');
        dispatch(setUserInfo(profile));
        //add google analytics code        
        let dataLayer = window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'userLogin', 'detail': {
            'memberId': process.env.REACT_APP_SPECBUILDER_ACCOUNT_ID,
            'accountType': 'Individual',
            'company': 'Example company',
            'subscriptionType': 'Annual'
          }
        });
      } else {
        dispatch(setLoginMessage({ isLoading: false, message: null }));
        dispatch(setMessage(true, '7018'));
      }
      dispatch(setAppLoader(false));
    });
  }
}

export const checkRosterPermission = () => {
  return dispatch => {
    dispatch(setUserPermissionLoader(true));
    const url = `${buildURL(Urls.RosterPermissions, null, null)}`;
    callAPI(url, 'get', null, true, (response) => {
      if (response && response.ResponseStatus) {
        const { ResponseData } = response;
        if (ResponseData && ResponseData.Permissions && ResponseData.Permissions.length) {
          dispatch(setUserPermission(ResponseData));
        } else {
          dispatch(setUserPermission(null));
        }
      } else {
        const uuidNumber = getUUIDNumberFromLocalStorage(url);
        dispatch(setMessage(true, '4003', uuidNumber));
      }
    });
  }
}

// TODO : Dispatch Entitlement Handler.
export const dispatchEntitlementHandler = (data, accountNumber, dispatch) => {
  const { accountDetails, membershipTypeDetails, orderStatus, demographyDetails } = data.otherInfo;
  const memberData = assign(orderStatus, membershipTypeDetails);

  // Check member is Historical Member
  memberData.isHistoricalMember = memberData.StatusName && memberData.StatusName.toLowerCase() === 'historical';
  const entitlementList = data;
  let appEntitlementList = [];
  if (entitlementList && entitlementList.menuList) {
    forEach(entitlementList.menuList, (menu) => { forEach(menu.child, (link) => { appEntitlementList.push({ title: link.title, path: link.url }); }); });
  }

  // if accountDetails.IsTestRecord is true set Test Mode true
  if (accountDetails.IsTestRecord) {
    dispatch(setTestMode(true));
  } else {
    dispatch(setTestMode(false));
  }

  dispatch(setMemberData(memberData));
  dispatch(setEntitlement(entitlementList));
  dispatch(setAppEntitlement(appEntitlementList));
  dispatch(setLoggedInMemberDetails(accountDetails));
  dispatch(setDemoGraphy(demographyDetails));
  dispatch(setMiniAppLoader(false));

  // Set the data in localStorage for Ballot and Work Item Apps
  const appData = {
    UserId: accountDetails.MemberId,
    AccountNo: accountNumber
  }
  setCookie(cookieKeysEnum["APP-DATA"], encrypt(JSON.stringify(appData)), process.env.REACT_APP_COOKIES_EXPIRATION_TIME);
  dispatch(setAppLoader(false));
}

// TODO : GET Entitlement List.
export const fetchMemberTypeDetails = () => {
  return (dispatch, getState) => {
    const { user } = getState();
    const { userInfo } = user;
    // Hit Api only the case of Member
    if (get(userInfo, 'isMember')) {
      const accountNumber = userInfo.accountNumber ? userInfo.accountNumber : userInfo.memberAccountNumber;
      dispatch(setMiniAppLoader(true));
      const url = buildURL(Urls.EntitlementList, null, { accountNumber });
      callAPI(url, 'get', null, true, (response) => {
        if (response && response.ResponseStatus && response.ResponseData && response.ResponseData.otherInfo) {
          dispatchEntitlementHandler(response.ResponseData, accountNumber, dispatch);
        } else {
          const uuidNumber = getUUIDNumberFromLocalStorage(url);
          dispatch(setMessage(true, '4003', uuidNumber));
          dispatch(setMiniAppLoader(false));
        }
      });
    }
  }
}
export const fetchMemberCommitteeProfileList = (dispatch, callback) => {
  dispatch(setMiniAppLoader(true));
  const url = `${buildURL(Urls.MemberCommitteeProfile, null, null)}`;
  callAPI(url, 'get', null, true, (response) => {
    if (response && response.ResponseStatus) {
      callback(response.ResponseData);
    } else {
      const uuidNumber = getUUIDNumberFromLocalStorage(url);
      dispatch(setMessage(true, '4003', uuidNumber));
    }
    dispatch(setMiniAppLoader(false));
  });
}

export const fetchMemberActiveMainCommittee = (dispatch, callback, isHideLoader) => {
  if (!isHideLoader) {
    dispatch(setMiniAppLoader(true));
  }
  const url = `${buildURL(Urls.MemberActiveMainCommittee, null, null)}`;
  callAPI(url, 'get', null, true, (response) => {
    if (response && response.ResponseStatus) {
      callback(response.ResponseData);
    } else {
      const uuidNumber = getUUIDNumberFromLocalStorage(url);
      dispatch(setMessage(true, '4003', uuidNumber));
      callback([]);
    }
    if (!isHideLoader) {
      dispatch(setMiniAppLoader(false));
    }
  });
}

export const getMemberSubCommittees = (committeeId, dispatch, callback, isHideLoader) => {
  if (!isHideLoader) {
    dispatch(setMiniAppLoader(true));
  }
  const url = `${buildURL(Urls.MemberSubCommittee, null, { committeeId })}`;
  callAPI(url, 'get', null, true, (response) => {
    if (response && response.ResponseStatus) {
      callback(response.ResponseData);
    } else {
      const uuidNumber = getUUIDNumberFromLocalStorage(url);
      dispatch(setMessage(true, '4003', uuidNumber));
    }
    dispatch(setMiniAppLoader(false));
  });
}

export const bannerFeatureAction = (callback) => {
      const url = buildURL(Urls.bannerFeature, null, null);
      callAPI(url, 'get', null, true, (response) => {
          if (response && response.status) {
              callback(response.content);
          } else {
              callback(null);
          }
      });
}

// TODO : Auth Action for OKTA.
export const oktaLoginAction = (data, dispatch, cb) => {
  const { userInfo } = data;
  dispatch(setMiniAppLoader(true));
  // To Do remove open auth token public url
  removeCookie("open-auth-token");
  const sessionTime = new Date().getTime();
  setCookie(cookieKeysEnum['APP_SESSIONID'], sessionTime, process.env.REACT_APP_COOKIES_EXPIRATION_TIME);
  const url = data['request_mode'] === LOGIN_USER_MODEL.MEMBER_LOGIN ? `${buildURL(Urls.MemberLogin, null, null)}` : `${buildURL(Urls.verifyInfo, null, null)}`;
    
    // proxymemberemail is added temporarly for identity delegation work around, will be changed in future
    callAPI(url, 'post', {proxymemberemail: localStorage.getItem('proxymemberemail')}, false, (response) => {
      dispatch(setMiniAppLoader(false));
    // In case user is there is MAE and API is working, then we will get atleast Account Number in response
    if (response && response.ResponseData && response.ResponseStatus) {
      userInfo.memberAccountNumber = get(response.ResponseData, 'accountNumber');
      // In case user is a non member/customer, then ResponseStatus will be false
      setCookie(cookieKeysEnum["_MyASTM"], userInfo.memberAccountNumber, process.env.REACT_APP_COOKIES_EXPIRATION_TIME);
      setCookie(cookieKeysEnum["ILSCookie"], encrypt(`${userInfo.email};${userInfo.memberAccountNumber}`), process.env.REACT_APP_COOKIES_EXPIRATION_TIME);
      setCookie(cookieKeysEnum["ACCOUNT-ID"], process.env.REACT_APP_SPECBUILDER_ACCOUNT_ID, process.env.REACT_APP_COOKIES_EXPIRATION_TIME);
      userInfo.isMember = true;
      userInfo.IdentityDelegation = response.ResponseData.identityDelegation;
      // If okta login is successful then login user can be a customer and we need to handle it differently.
      dispatch(setUserInfo(userInfo));
      dispatchEntitlementHandler(response.ResponseData.entitlement, userInfo.memberAccountNumber, dispatch); // Dispatch entitlement found in Response api.
      setCookie(cookieKeysEnum["IS-OKTA-VERIFIED"], true, process.env.REACT_APP_COOKIES_EXPIRATION_TIME);
      cacheManager.setItem('user-info', JSON.stringify(userInfo));
      window.localStorage.setItem('isMemberStillLoginInAnotherTab', true)
      cb({ isMember: userInfo.isMember, isAPIPassing: true });
    } else {
      // Verify whether account number exist in MAE system then update user info 
      if (get(response, 'ResponseData') && get(response.ResponseData, 'accountNumber')) {
        userInfo.memberAccountNumber = get(response.ResponseData, 'accountNumber');
        userInfo.isOrderInProcess = get(response.ResponseData, 'isOrderInProcess');
      } else { // else dispatch error message with no account available.
        userInfo.memberAccountNumber = "N/A";
      }
      // In Case of No Result Found in coarse API but okta enabled. 
      userInfo.isMember = false;
      cacheManager.setItem('user-info', JSON.stringify(userInfo));
      setCookie(cookieKeysEnum["IS-OKTA-VERIFIED"], true, process.env.REACT_APP_COOKIES_EXPIRATION_TIME);
      dispatch(setUserInfo(userInfo));
      window.localStorage.setItem('isMemberStillLoginInAnotherTab', false)
      cb({ isMember: userInfo.isMember, isAPIPassing: false });
    }
  });
}

export const getMyToolsMenuPermissionsAction = (req, callback, dispatch) => {
  const url = buildURL(Urls.MyToolsMenuPermissions, null, null) + encodeText(req.memberId) + `?MAEUserID=${req.MAEUserID}`;
  callAPI(url, 'get', null, true, (response) => {
    if (response && response.ResponseStatus) {
      callback(response.ResponseData);
    } else {
      callback();
      const uuidNumber = getUUIDNumberFromLocalStorage(url);
      dispatch(setMessage(true, '4003', uuidNumber));
    }
  });
}

export const fetchMemberCommitteeInfo = (callback, isHideLoader) => {
  return (dispatch, getState) => {
    const { user } = getState();
    const { userInfo } = user;
    const accountNumber = userInfo.accountNumber ? userInfo.accountNumber : userInfo.memberAccountNumber;

    if (!isHideLoader) {
      dispatch(setMiniAppLoader(true));
    }

    const url = buildURL(Urls.MemberCommitteeInfo, null, { accountNumber });
    callAPI(url, 'get', null, true, (response) => {
      if (response && response.ResponseStatus) {
        callback(response.ResponseData);
      } else {
        const uuidNumber = getUUIDNumberFromLocalStorage(url);
        dispatch(setMessage(true, '4003', uuidNumber));
        callback([]);
      }
      if (!isHideLoader) {
        dispatch(setMiniAppLoader(false));
      }
    });
  }
}

export const fetchMemberRecentActivities = (callback, isHideLoader) => {
  return (dispatch, getState) => {
    const { user } = getState();
    const { userInfo, loggedInMemberData } = user;
    const { MAEUserID: maeUserId } = userInfo;
    const { MemberId: memberId } = loggedInMemberData;

    if (!isHideLoader) {
      dispatch(setMiniAppLoader(true));
    }

    const url = buildURL(Urls.MemberRecentActivities, null, { memberId, maeUserId });
    callAPI(url, 'get', null, true, (response) => {
      if (response && response.ResponseStatus) {
        callback(response.ResponseData);
      } else {
        const uuidNumber = getUUIDNumberFromLocalStorage(url);
        dispatch(setMessage(true, '4003', uuidNumber));
        callback([]);
      }
      if (!isHideLoader) {
        dispatch(setMiniAppLoader(false));
      }
    });
  }
}

export const fetchBookMovement = (data, dispatch, callback, isHideLoader) => {
  if (!isHideLoader) {
    dispatch(setMiniAppLoader(true));
  }
  const url = `${buildURL(Urls.BookMovementList, null, null)}`;
  callAPI(url, 'post', data, true, (response) => {
    if (response && response.ResponseStatus) {
      callback(response.ResponseData);
    } else {
      const uuidNumber = getUUIDNumberFromLocalStorage(url);
      dispatch(setMessage(true, '4003', uuidNumber));
      callback(null);
    }
    if (!isHideLoader) {
      dispatch(setMiniAppLoader(false));
    }
  });
}

export const sendChangeOfEmployementRequestEmail = (postData, callback, isHideLoader) => {
  return (dispatch, getState) => {
    const { user } = getState();
    const { userInfo } = user;
    const accountNumber = userInfo.accountNumber ? userInfo.accountNumber : userInfo.memberAccountNumber;

    if (!isHideLoader) {
      dispatch(setMiniAppLoader(true));
    }

    const url = buildURL(Urls.ChangeOfEmploymentNotification, null, { accountNumber });
    callAPI(url, 'post', postData, true, (response) => {
      if (response && response.ResponseStatus) {
        callback(true);
      } else {
        const uuidNumber = getUUIDNumberFromLocalStorage(url);
        dispatch(setMessage(true, '4003', uuidNumber));
        callback(false);
      }
      if (!isHideLoader) {
        dispatch(setMiniAppLoader(false));
      }
    });
  }
}

export const fetchMemberPrimaryActivityData = (callback, isHideLoader) => {
  return (dispatch, getState) => {
    const { user } = getState();
    const { userInfo } = user;
    const accountNumber = userInfo.accountNumber ? userInfo.accountNumber : userInfo.memberAccountNumber;

    if (!isHideLoader) {
      dispatch(setMiniAppLoader(true));
    }

    const url = buildURL(Urls.MemberPrimaryActivityData, null, { accountNumber });
    callAPI(url, 'get', null, true, (response) => {
      if (response && response.ResponseStatus) {
        callback(response.ResponseData);
      } else {
        const uuidNumber = getUUIDNumberFromLocalStorage(url);
        dispatch(setMessage(true, '4003', uuidNumber));
      }
      if (!isHideLoader) {
        dispatch(setMiniAppLoader(false));
      }
    });
  }
}

export const fetchMemberOfficerHistoryDetail = (callback, isHideLoader) => {
  return (dispatch) => {

    if (!isHideLoader) {
      dispatch(setMiniAppLoader(true));
    }

    const url = buildURL(Urls.MemberOfficerHistoryDetail, null, null);
    callAPI(url, 'get', null, true, (response) => {
      if (response && response.ResponseStatus) {
        callback(response.ResponseData);
      } else {
        const uuidNumber = getUUIDNumberFromLocalStorage(url);
        dispatch(setMessage(true, '4003', uuidNumber));
      }
      if (!isHideLoader) {
        dispatch(setMiniAppLoader(false));
      }
    });
  }
}

export const updateDemographicInfo = (requestBody, dispatch, callback) => {
  dispatch(setMiniAppLoader(true));

  const url = buildURL(Urls.updateDemographicInfo, null, null);
  callAPI(url, 'post', requestBody, true, (response) => {
    if (response && response.ResponseStatus) {
      dispatch(updateNicknameInDemograpgy(requestBody.NickName));
    } else {
      const uuidNumber = getUUIDNumberFromLocalStorage(url);
      dispatch(setMessage(true, '4003', uuidNumber));
    }

    dispatch(setMiniAppLoader(false));
    callback();
  });
}

export const getCollabAreaPermission = (accountNumber, dispatch, callback) => {
  const url = buildURL(Urls.CollabAreaPermission, null, { accountNumber: encrypt(accountNumber) });

  callAPI(url, 'get', null, true, (response) => {
    if (response && response.Status) {
      callback(response.Result);
    } else {
      const uuidNumber = getUUIDNumberFromLocalStorage(url);
      dispatch(setMessage(true, '4003', uuidNumber));
    }
  });
}

export const addressValidation = (address, dispatch, callback) => {
  dispatch(setMiniAppLoader(true));
  const url = buildURL(Urls.addressValidation, null, null);
  callAPI(url, 'post', address, true, (response) => {
    dispatch(setMiniAppLoader(false));
    if (response && response.ResponseStatus) {
      callback(response.ResponseData);
    } else {
      const uuidNumber = getUUIDNumberFromLocalStorage(url);
      dispatch(setMessage(true, '4003', uuidNumber));
    }
  });
}

// Add New Urls
export const getNegativeAndCommentAction = (callback, dispatch) => {
  const url = buildURL(Urls.NegativeAndComment, null, null)
  callAPI(url, 'get', null, true, (response) => {
    if (response && response.ResponseStatus) {
      callback(response.ResponseData);
    } else {
      callback();
      const uuidNumber = getUUIDNumberFromLocalStorage(url);
      dispatch(setMessage(true, '4003', uuidNumber));
    }
  });
}

export const getOutstandingBallotsListAction = (req, callback, dispatch) => {
  const url = buildURL(Urls.OutstandingBallots, null, null) + encodeText(req.memberId);
  callAPI(url, 'get', null, true, (response) => {
    if (response && response.ResponseStatus) {
      callback(response.ResponseData);
    } else {
      callback();
      const uuidNumber = getUUIDNumberFromLocalStorage(url);
      dispatch(setMessage(true, '4003', uuidNumber));
    }
  });
}

export const getNextMeetingListAction = (callback, dispatch) => {
  const url = buildURL(Urls.NextMeeting, null, null);
  callAPI(url, 'get', null, true, (response) => {
    if (response && response.ResponseStatus) {
      callback(response.ResponseData);
    } else {
      callback();
      const uuidNumber = getUUIDNumberFromLocalStorage(url);
      dispatch(setMessage(true, '4003', uuidNumber));
    }
  });
}

export const getAllWorkItemAction = (req, callback, dispatch) => {
  const url = buildURL(Urls.AllWorkItem, null, null) + encodeText(req.memberId);
  callAPI(url, 'get', null, true, (response) => {
    if (response && response.ResponseStatus) {
      callback(response.ResponseData);
    } else {
      callback();
      const uuidNumber = getUUIDNumberFromLocalStorage(url);
      dispatch(setMessage(true, '4003', uuidNumber));
    }
  });
}

export const getCollabWorkItemAction = (req, callback, dispatch) => {
  const url = buildURL(Urls.CollabWorkItem, null, null) + encodeText(req.MAEUserID); 
  callAPI(url, 'get', null, true, (response) => {
    if (response && response.ResponseStatus) {
      callback(response.ResponseData);
    } else {
      callback();
      const uuidNumber = getUUIDNumberFromLocalStorage(url);
      dispatch(setMessage(true, '4003', uuidNumber));
    }
  });
}

export const getCollabListAction = (req, callback, dispatch) => {
  const url = buildURL(Urls.CollabList, null, null) + encodeText(req.memberId); 
  callAPI(url, 'get', null, true, (response) => {
    if (response && response.ResponseStatus) {
      callback(response.ResponseData);
    } else {
      callback();
      const uuidNumber = getUUIDNumberFromLocalStorage(url);
      dispatch(setMessage(true, '4003', uuidNumber));
    }
  });
}